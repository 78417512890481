import React from 'react';
import { Segment, Image, Divider, Button } from 'semantic-ui-react';

import './style.css';

export default function ServiceMessage() {
  return (
    <div className="service-message">
      <Segment basic textAlign="center">
        <Image src="/images/logo-dark.svg" size="small" centered />

        <Divider hidden />

        <h4>Oops! Da ist etwas schiefgelaufen</h4>
        <h5 style={{ color: 'grey' }}>
          Aktualisieren Sie, um es erneut zu versuchen.
          <br />
          Wenn das Problem weiterhin besteht, wenden Sie sich bitte an unseren Kundendienst.
        </h5>

        <Button content="Refresh" icon="refresh" onClick={() => window.location.reload()} />
      </Segment>
    </div>
  );
}
